/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@font-face {
	font-family: 'Gotham-Bold';
	src: url('./fonts/Gotham-Bold.otf') format('openType');
}
/* @font-face {
	font-family: 'Open Sans Bold';
	src: url('./fonts/OpenSans-Bold.woff') format('woff'),
       url('./fonts/OpenSans-Bold.woff2') format('woff2'),
} */
@font-face {
	font-family: 'Open Sans';
	src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap'),
	     url('./fonts/OpenSans-Regular.ttf') format('truetype'),
	     url('./fonts/OpenSans-Light.ttf') format('truetype'),
       url('./fonts/OpenSans-Regular.otf') format('openType');
}
html,
body {
  height: 100%;
  background-color: #f7f7f7;
  font-family: 'Open Sans', sans-serif !important;
  margin: 0;
  padding: 0;
}
h1,h2,h3,h4,h5,h6 {
  font-family: 'Gotham-Bold' !important;
}
/* added to cater zoom in issue on ios, ios zooms in on font size being below 16px */
input, input:active, input:focus, input:focus-within, input:hover, input:visited {
  font-size: 16px!important;
}
.goth-heading {
  font-family: 'Gotham-Bold' !important;
}
.row{ margin-left: 0 !important; margin-right: 0 !important;}

.full-width {
  width: 100%;
}
.breadcrumbs {
  font-family: 'Gotham-Bold';
  /* margin-left: 25px; */
}
.mat-form-field{
  line-height: 1.15 !important;
}

.mat-form-field input {
  font-family: 'Open Sans';
  line-height: 0.88;
}

::ng-deep .mat-form-field-invalid .mat-form-field-flex {
  border: 2px solid red !important;
  background-color: #ffe8e8;
}

::ng-deep .mat-form-field-underline{
  position: unset;
  width: 100%;
  pointer-events: none;
  transform: scale3d(1, 1.0001, 1);
}

.cursor-pointer{
  cursor: pointer;
}

.disable-box{
  background-color: #FFE8E8;
  color : #FF0000;
}

.warning-text{
  font-size: 12px;
  text-align: left;
}

.h-45{
  height: 45px;
}

.margin-top-5px{
  margin-top: 5px !important;
}

.no-padding {
  padding-left:0px;
  padding-right:0px;
}

.center-row {
  height: 100%;
  /* margin-top: 7vh; */
}

.mb-25px{

  margin-bottom: 25px !important;

}

.mb-10px{
  margin-bottom: 10px;
}

/* .footer-stick {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
} */

@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}

.theme-light {
  --color-primary: #0B3B60;
  --color-secondary: #fbfbfe;
  --color-accent: #0B3B60;
  --font-color: #000000;
}
.theme-dark {
  --color-primary: #D90615;
  --color-secondary: #243133;
  --color-accent: #000000;
  --font-color: #ffffff;
}


a:hover {
  cursor: pointer;
}

.body {
  margin-top: 2vh;
  margin-bottom: 2vh;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f7f7f7 !important;
}

.sp-body{
  background-color: #f7f7f7;
  /* margin-top: 2vh; */
  margin-bottom: 2vh;
  /* padding-top: 10px; */
  padding-bottom: 30px;

}
.sp_registration_img {
  background: 
  linear-gradient(to bottom,transparent 0,#000 125%), 
  url("../images/landing/Rectangle_794.jpg");
  background-position-x: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100%;
  min-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

}

.sp_login_left {
  padding-right: 0px;
  padding-left: 0px;
  height: inherit;
}

.sp_login_right {
  padding-left: 0px;
  padding-right: 0px;
  height: inherit;
}

img.cover{
  object-fit: cover;
  /* object-position: top; */
}

.sp_center_row {
  min-height: 100%;
}

.sp-form-heading {
  padding-bottom: 10px;
}

.sp-form-input {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 3.8em;
  z-index: 1;
  background-color: #F5F5F5;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #707070;
}

.sp-form-button {
  color: white;
  height: 3.3em;
}

.sp-form-button:hover,
.sp-form-button:focus {
  color: white;
}

.sp-hyperlink-div {
  padding-top: 15px;
  text-align: left;
}

.sp-hyperlink-div-modal {
  padding-top: 15px;
  text-align: center;
}


.sp-red-border-bold {
  border-width: 2.5px;
}

.sp-footer-head,
.sp-footer-head a {
  /* color: red; */
  margin-bottom: 0%;
  font-size: 1.3em;
}

.footer {
  height: 60px;
  bottom: 0px;
  line-height: 150%;
  background-color: white;
  border-top: 1px solid #ccc;
  font-size: 0.8em;
  padding: 12px;
  margin-top: 1%;
  margin-bottom: 1%;
}

.header {
  height: 60px;
}

.sp-header-lang {
  padding-top: 18px;
}

.sp-menubar {
  border-bottom: 1px solid #ccc;
  height: 2.4em;
}

.sp-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.sp-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.ngx-otp {
  display: flex;
  justify-content: left;
}

/* Create a custom sp-checkbox */
.sp-checkmark {
  position: absolute;
  top: auto;
  left: 1px;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1.5px solid black;
  border-radius: 5px;
  box-shadow: 2px 2px #e4e4e4;
}

/* On mouse-over, add a red background color */
.sp-checkbox:hover input ~ .sp-checkmark {
  /* background-color: rgb(255, 4, 4); */
}

/* When the sp-checkbox is checked, add a darkred background */
.sp-checkbox input:checked ~ .sp-checkmark {
  /* background-color: rgb(187, 3, 9); */
  background-color: #FFFFFF;
}

/* Create the sp-checkmark/indicator (hidden when not checked) */
.sp-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the sp-checkmark when checked */
.sp-checkbox input:checked ~ .sp-checkmark:after {
  display: block;
}

/* Style the sp-checkmark/indicator */
.sp-checkbox .sp-checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.navbar-static-top {
  z-index: 5;
}

.sp-middle-element {
  min-height: 100%;
  /* height: 100%; */
  align-items: center;
  display: flex;
  justify-content: center;
}

.sp-form-button-cancel {
  background-color: rgb(221, 221, 221);
  color: rgb(0, 0, 0);
  height: 3.3em;
}

.sp-form-button-cancel:hover,
.sp-form-button-cancel:focus {
  background-color: rgb(92, 92, 92);
  color: rgb(0, 0, 0);
}

.sp-white-text {
  color: white;
}
.form-group {
  margin-bottom: 0px;
}

.help-block {
  position: relative;
  /* font-weight: bold; */
  left: 1px;
  font-size: 11px;
  margin: 0px;
  top: -9px;
  color: red !important;
}

.sp-home-enabled-button {
  background-color: #424141;
  color: white;
  width: 7em;
  border-radius: 0px;
}

.sp-home-enabled-button:hover,
.sp-home-enabled-button:active,
.sp-home-enabled-button:focus{
  outline: white 4px double;
  outline-offset: -4px;
  color: white;
}

.sp-back {
  font-size: 2em;
  margin: 15px 0 0 0;
}
.sp-back a {
  visibility: hidden;
}

.content-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f9f7fb;
  font-size: 40px;
  font-weight: 600;
  padding-top: 250px;
  padding-bottom: 10px;
  font-family: Gotham-Bold;
}

.content-banner {
  display: flex;
  justify-content: center;
  color: #f9f7fb;
  font-size: 15px;
  margin: 0;
}

@media all and (max-width: 970px) {
  .sp_center_row {
    margin: 10px 0px;
    min-height: 100vh !important;
    height: 1px;
    overflow: auto;
  }
  .footer {
    border-top: 0px;
  }
  .sp-menubar {
    border-bottom: 0px;
  }
  .form-control {
    font-size: 16px;
  }

  .sm-no-padding{
    padding: 0px;
  }
  .sm-no-margin{
    margin: 0px;
  }

  .sp-back{
    font-size: 1.2em;
  }

  .mb-10px{
    margin-bottom: 0px;
  }
  .ngx-otp {
    display: flex;
    justify-content: center !important;

  }
  .mobile-text-align {
    text-align: center;
  }
}

.logo {
  width: 157px;
  height: 30px;
}

@media all and (max-width: 767px) {
  
  .sp-back a {
    visibility: visible;
  }

  .xs-no-padding{
    padding: 0px;
  }
  .logo {
    width: 125px;
    height: 48px;
  }
}

 /* over-ridding the bootstrap alet-danger */
.alert-danger {
  background-color: #FFE8E8;
  color: #FF0000;
  text-align: left;
  border: none !important;
}

.alert-info {
  color: #008f18;
  background-color: #d9f3de;
  text-align: left;
  border-color: #d9f3de;
}

.alert-danger .close {
  opacity: 0.65;
}

.alert-danger .close:hover,
.alert-danger .close:focus,
.alert-danger .close:active {
  opacity: 1;
  color: inherit;
}

.sp-verf-choice{
  width: 12em;
}

.click{
  cursor: pointer;
}

.click:hover,
.click:focus,
.click:active{
  outline: white 1px auto;
}


.sp-red-button{
  color: #fff !important;
  background-color: #d9534f !important;
  border-color: #fff !important;
}

.sp-red-button:hover,
.sp-red-button:focus,
.sp-red-button:active{
  color: #fff !important;
  background-color: #c9302c !important;
  border-color: #fff !important;
}

.sp-green-button{
  background-color: #63D05A !important;
}

.sp-green-button:hover,
.sp-green-button:active,
.sp-green-button:focus{
  background-color: #65A969 !important;
}

/* Date panel css */

.owl-picker-dropdown{
  top: auto !important;
  width: 100% !important;
}

.sp-date {
  margin: 0.3em 5px;
  width: 100%;
  padding: 0.5em 0em;
}


/* End Date panel css */


.owl-dt-inline-container,
.owl-dt-inline {
  width: 100%
}

.owl-dt-inline-container .owl-dt-calendar{
  height: 100%;
}

.owl-dt-container{
  font-size: 1em;
}

.height-100{
  height: 100% !important;
}

.center-row {
  /* height: 86vh; */
  /* margin-top: 7vh; */
  background-color: #F7f7f7 !important;
  height: calc(96vh - 64px);
}
.main-class{
  overflow-y: hidden;
}

.mobile-content-heading{
  font-family: Gotham-Bold;
  font-size: 34px;
  font-weight: 600;
  color: #f9f7fb;
  text-align: center;

}

.mobile-content-banner{
  color: #f9f7fb;
  font-size: 15px;
  text-align: center;
  margin: 0;
}

.dropdown-menu .list-i .sp-menu-item:hover,
.dropdown-menu .list-i .sp-menu-item:focus{

  color: #FFF !important;
  text-decoration: underline !important;
  background-color: #2B2B2B !important;

}
.password-errors{
  margin-bottom: 2px;
  font-size: 11px;
}

.close-btn{

 color: #000 !important;
 opacity: 1;
 margin: 0;

}

.close-btn .mat-icon{
  transform: scale(1.5);
}

.chevron-right-bd-icon{

  color: black; 
  margin-left: 10px; 
  margin-right: 10px;

}

.eye-focus:focus {
  border: 2px solid;
}