/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
  background-color: #f7f7f7;
}
body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #f7f7f7;
}
.row{ margin-left: 0 !important; margin-right: 0 !important;}

.full-width {
  width: 100%;
}

.mat-form-field{
  line-height: 1.15 !important;
}

.cursor-pointer{
  cursor: pointer;
}

.h-45{
  height: 45px;
}

.margin-top-5px{

  margin-top: 5px !important;

}

.no-padding {
  padding-left:0px;
  padding-right:0px;
}

.center-row {
  height: 100%;
  /* margin-top: 7vh; */
}

.ml-10px{
  margin-left: 10px;
}
.ml-5px{
  margin-left: 5px;
}

/* .footer-stick {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
} */
/* Add global styles main.css */
